<template>
  <div
    class="data-card course-card"
    :class="{ card: listType == 'card', list: listType == 'list' }"
    @click="jump"
  >
    <div class="tag" v-show="listType == 'card'">
      <span class="green" v-if="dataSource.progress == 100">
        {{ $t("CM_Completed")
        }}<!-- 已完成 -->
      </span>
      <span class="red" v-else>
        {{ $t("CM_Unfinished")
        }}<!-- 未完成 -->
      </span>
    </div>
    <div class="cover">
      <img :src="dataSource.coverImg" alt="cover" />
      <div class="course-tag" :class="dataSource.isRequired && 'required-tag'">
        {{ $t(dataSource.isRequired ? 'Pub_Required' : 'Pub_Elective') }}
      </div>
    </div>
    <div class="detail">
      <h5>
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ dataSource.courseName }}
            </div>
          </template>
          {{ dataSource.courseName }}
        </a-popover>
      </h5>
      <div class="condition mt12" v-show="listType == 'list'">
        <span class="label">{{ $t("CM_CCondition") }}：</span>
        <div class="complete-condition">
          <!-- 完成条件 -->
          <div class="checkbox" v-if="1 & dataSource.completeCondition">
            <a-checkbox :checked="(1 & dataSource.completeStatus) > 0">
              {{ $t("CompleteCourseChapters")
              }}<!-- 完成课程章节 -->
            </a-checkbox>
          </div>
          <div class="checkbox" v-if="2 & dataSource.completeCondition">
            <a-checkbox :checked="(2 & dataSource.completeStatus) > 0">
              {{ $t("CompleteCourseExam", { type: $t('BN_Comm_Completed') })
              }}<!-- 完成课程考试 -->
            </a-checkbox>
            <span class="tip">
              (
                {{ dataSource.examOpenCondition === 1 ? $t('AfterTheCompleteCourseCanBeExam') + ';' : '' }}
                {{ dataSource.examCompleteCondition === 1 ? $t('SubmitIsCompleted') : $t('PassIsCompleted') }}
              )
            </span>
          </div>
          <div class="checkbox" v-if="4 & dataSource.completeCondition">
            <a-checkbox :checked="(4 & dataSource.completeStatus) > 0">
              {{ $t("CompleteCourseAssignments", { type: $t('BN_Comm_Completed') })
              }}<!-- 完成课程作业 -->
            </a-checkbox>
            <span class="tip">
              (
                {{ dataSource.homeworkCompleteCondition === 1 ? $t('SubmitIsCompleted') : $t('ApprovedIsCompleted') }}
              )
            </span>
          </div>
          <div class="checkbox" v-if="8 & dataSource.completeCondition">
            <a-checkbox :checked="(8 & dataSource.completeStatus) > 0">
              {{ $t("CompleteCourseEvaluation", { type: $t('BN_Comm_Completed') })
              }}<!-- 完成课程评价 -->
            </a-checkbox>
          </div>
          <!-- <div class="checkbox" v-else-if="dataSource.isHaveStar">
            <a-checkbox :checked="dataSource.isCompletedStar">
              {{ $t("CompleteCourseEvaluation")
              }}
            </a-checkbox>
          </div> -->
        </div>
      </div>
      <div class="row" style="margin-top: 0">
        {{ $t("Pub_Tab_LastStudy") }}：{{ dateFormat(dataSource.lastTime) }}
        <!-- 上次学习 -->
      </div>
      <div class="rate mt12">
        <span v-show="listType == 'list'"
          >{{ $t("course.total_progress") }}：<!-- 总进度 --></span
        >
        <a-progress
          size="small"
          strokeColor="#468CE3"
          :percent="dataSource.progress"
        >
          <template #format>
            <div class="rate-format">{{ dataSource.progress }}%</div>
          </template>
        </a-progress>
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <div class="btn">
        {{ $t("view_detail") }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
export default {
  name: "CourseCard",
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const router = useRouter();

    const jump = () => {
      if (props.newWindow) {
        window.open(
          `/course/center?ddtab=true&id=${props.dataSource.courseId}`
        );
      } else {
        router.push({
          path: "/course/center",
          query: {
            id: props.dataSource.courseId,
          },
        });
      }
    };

    return {
      dateFormat,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.course-tag {
  position: absolute;
  left: 8px;
  top: 10px;
  background-color: #89bb22;
  font-size: 14px;
  line-height: 28px;
  padding: 0 8px;
  z-index: 2;
  border-radius: 4px;
  color: #fff;
}
.required-tag {
  background-color: #ff7800;
}
.card .course-tag {
  left: auto;
  right: 8px;
}
</style>
